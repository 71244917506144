<template>
   <navi-bar>
     <img src="@/assets_mobile/img/msgmy_off.png"/>
     <span>{{$t('front.mypage.message')}}<em>Message</em></span>
   </navi-bar>
  <section class="sub-box" v-if="item">
    <div class="msgreadwrap">
      <div class="msgreadhead">
        <h2 class="msgreadname">
<!--          <em class="noticeicon">안내</em>-->
          {{item.msgTitle}}
<!--          <em class="newicon">N</em>-->
        </h2>
        <p class="msgreadtime">
          <span class="logo"><img src="@/assets/img/duggubilogo.svg" /></span>
          <span class="time">{{dateFormat(item.sendDate)}}</span>
        </p>
      </div>
      <div class="msgreadconwrap">
        <div class="msgreadcon">
          {{item.msgDesc}}
        </div>
      </div>
    </div>

    <div class="sbtnwrap">
      <a class="all" v-if="prevItem" @click="onMessageClick(prevItem, true)">{{$t('front.board.prev')}}</a>
      <a class="all" @click="onMessageList(true)">{{$t('front.board.list')}}</a>
      <a class="all" v-if="nextItem" @click="onMessageClick(nextItem, true)">{{$t('front.board.next')}}</a>
    </div>
  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import messageRead from '@/views/member/mypage/message/read'

export default {
  name: 'msgread',
  mixins: [messageRead],
  components: {
    NaviBar
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped>
.msgreadhead {display: block !important;}
</style>
